<template>
    <div class="orderEntry container">
       <p class="h6 text-center text-primary"><b>{{ pageTitle }}</b></p>

    <!--------------------------------- Modal: Payments -------------------------------------------->

    <CModal title="Payment" color="info" :show.sync="paymentModal">	
      <div slot="header">
        <h5>Payment Entry</h5>
      </div>	
      <div slot="footer">
        <CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="closePaymentModal()">Close</CButton>
      </div>	

      <vue-good-table ref="paymentTable"
        :columns=	"[ {label: 'Date', field: 'date', thClass: 'text-center',},
                    {label: 'Bank Paid', field: 'paidBank', thClass: 'text-center',},
                    {label: 'Cash Paid', field: 'paidCash', thClass: 'text-center',}]"
        :rows="order.payments"
        styleClass="vgt-table bordered condensed"
        @on-row-click="paymentRowClicked"
        :selectOptions="{enabled: true,selectOnCheckboxOnly: true}"
      >
        <div slot="table-actions">
          <CButton color="dark" class="mr-3 text-right" v-on:click.prevent="addMorePayments()">Add</CButton>
        </div>
        <div slot="selected-row-actions">
          <CButton color="dark" class="mr-3 text-right" 
                        v-on:click.prevent="modifyPaymentClicked()">Modify</CButton>
          <CButton color="dark" class="mr-3 text-right" 
                        v-on:click.prevent="removePaymentClicked()">Remove</CButton>
        </div>
        <!-- <div slot="selected-row-actions">
          <CButton :disabled="this.rowPaymentSelected == null" color="dark" class="mr-3 text-right" 
                        v-on:click.prevent="modifyPaymentClicked()">Modify</CButton>
          <CButton :disabled="this.rowPaymentSelected == null" color="dark" class="mr-3 text-right" 
                        v-on:click.prevent="removePaymentClicked()">Remove</CButton>
        </div> -->
      </vue-good-table>

      <br><br>
      <div v-if="updatePayment==true">  
        <div class="form-group row">
          <label class="col-sm-4 col-form-label font-weight-bold text-right">Payment Date</label>
          <div class="col-sm-4 text-left">
            <datepicker v-model="curPayment.date" name="curPayDate" :disabled-dates="state.disabledDates"></datepicker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label font-weight-bold text-right">Bank Paid</label>
          <input type="number" class="col-sm-4 form-control" v-model="curPayment.paidBank">
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label font-weight-bold text-right">Cash Paid</label>
          <input type="number" class="col-sm-4 form-control" v-model="curPayment.paidCash">
        </div>

        <div class="form-group row justify-content-center">
            <CButton :disabled="curPayment.date==null || (curPayment.paidBank<=0 && curPayment.paidCash<=0)" color="dark" 
                        class="ml-2 mr-2" v-on:click.prevent="savePayment()">Save</CButton>
            <CButton color="dark" 
                        class="ml-2 mr-2" v-on:click.prevent="updatePayment=false">Cancel</CButton>
                        
        </div>
      </div>

    </CModal>

    <!--------------------------------- Modal: Order Item - Add/Modify  -------------------------------------------->

    <CModal title="Order Item" color="info" :show.sync="orderItemsModal">	
      <div slot="header">
        <h5>Order Item</h5>
      </div>	

      <div slot="footer">
        <div class="form-group row">
          <CButton color="dark" class="ml-2 mr-2" :disabled="item2sel.price<=0" v-on:click.prevent="saveItem()">Save</CButton>
          <CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="orderItemsModal=false">Close</CButton>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Item1</label>
        <div class="col-sm-4 text-left">
          <select class="form-control" v-model="item1dd" @change="onItem1Change()">
            <option v-for="item1 in item1List" :value="item1">{{item1.name}}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="item2" class="col-sm-4 col-form-label font-weight-bold text-right">Item2</label>
        <div class="col-sm-4 text-left">
          <select class="form-control" v-model="item2dd" @change="onItem2Change()">
            <option v-for="item2 in item2List" :value="item2">{{item2.name}}</option>
          </select>
        </div>
      </div>

      <div v-if="orderType=='Purchase'" class="form-group row">
        <label for="desc" class="col-sm-4 col-form-label font-weight-bold text-right">Description</label>
        <div class="col-sm-4 text-left">
          <input type="text" :disabled="item2Selected==false" class="form-control" v-model="item2sel.description">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Unit Price</label>
        <div class="col-sm-4">
          <input type="number" :disabled="item2Selected==false" class="form-control text-right" 
                                v-model.number="item2sel.price" v-on:keyup="recalOrderRow()">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Unit Discount</label>
        <div class="col-sm-4">
          <input type="number" :disabled="item2Selected==false" class="form-control text-right" v-model="item2sel.discount" 
                  @focus="$event.target.select()" v-on:keyup="recalOrderRow()">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Quantity</label>
        <div class="col-sm-4">
          <input type="number" :disabled="item2Selected==false" class="form-control  text-right" 
                @focus="$event.target.select()" v-model.number="item2sel.qty" v-on:keyup="recalOrderRow()">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Tax Rate</label>
        <div class="col-sm-4">
          <input type="number" :disabled="item2Selected==false" class="form-control  text-right" 
                @focus="$event.target.select()" v-model.number="item2sel.taxrate" v-on:keyup="recalOrderRow()">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Tax</label>
        <div v-if="item2sel.taxrate != 0" class="col-sm-4 text-right">
          <label class="col-form-label font-weight-bold text-right">{{ item2sel.tax.toFixed(2) }}</label>
        </div>
        <div v-else class="col-sm-4">
          <input type="number" :disabled="item2Selected==false" class="form-control  text-right" 
                @focus="$event.target.select()" v-model.number="item2sel.tax" v-on:keyup="recalOrderRow()">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Total</label>
        <label class="col-sm-4 col-form-label font-weight-bold text-right">{{ item2sel.total.toFixed(2) }}</label>
      </div>

    </CModal>

    <!--------------------------------- 1. Customer Information -------------------------------------------->

    <div v-if="wiz == 1">

      <div class="row">
        <div class="col">
          <CButton color="dark" class="ml-3 float-left"  v-on:click.prevent="clearOrder()">Back</CButton>
        </div>
        <div class="col">
          <p class="text-center font-weight-bold">{{ partnerType }} Information</p>
        </div>
        <div class="col">
            <CButton color="dark" class="float-right" :disabled="order.partner.name==''"
                v-on:click.prevent="wizChange(2)">Next</CButton>
        </div>
      </div>

      <hr /><br>

      <div class="row justify-content-md-center">
        <label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
        <scale-loader :loading="loading"></scale-loader>
      </div>

      <form>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label font-weight-bold text-right">{{ partnerType }}</label>
          <div class="col-sm-5">
            <model-list-select :list="partnerList"
                              v-model="order.partner"
                              option-value="nbr"
                              option-text="name">
            </model-list-select>
          </div>
        </div>
      </form>

      <CCard v-if="order.partner.name != ''" :color="'secondary'">
        <CCardBody>
            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Name:</label>
              <label class="col-sm-8 col-form-label text-left">{{  order.partner.name }}</label>
              <!-- <input type="text" ref="partnerName" class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" 
                    placeholder="Required" v-model="order.partner.name"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">GSTN:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.GSTN }}</label>
              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.GSTN"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">PAN:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.PAN }}</label>
              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.PAN"> -->

              <label class="col-sm-2 col-form-label font-weight-bold text-right">Aadhar:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.Aadhar }}</label>
              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.Aadhar"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Phone:</label>
              <!-- <label class="col-sm-3 col-form-label text-left">{{  order.partner.phone }}</label> -->
              <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.phone">

              <label class="col-sm-2 col-form-label font-weight-bold text-right">Email:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.email}}</label>
              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.email"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Address1:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.address1 }}</label>
              <!-- <input type="text" class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.address1"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Address2:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.address2 }}</label>
              <!-- <input type="text" class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.address2"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Address3:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.address3 }}</label>
              <!-- <input type="text" class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.address3"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">State:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.state }}</label>
              <!-- <select class="col-sm-3 form-control form-select" v-model="order.partner.state" :disabled="order.partner.nbr!=''">
                <option v-for="state in stateList" v-bind:key="state" v-bind:value="state">
                  {{ state }}
                </option>
              </select> -->

              <label class="col-sm-2 col-form-label font-weight-bold text-right">Zipcode:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.zipcode }}</label>
              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.zipcode"> -->
            </div>
            
            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Transporter:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.transporter }}</label>

              <!-- <select class="col-sm-3 form-control form-select" v-model="order.partner.transporter">
                <option v-for="transporter in transporterList" v-bind:key="transporter.nbr" v-bind:value="transporter.name">
                  {{ transporter.name }}
                </option>
              </select> -->

              <label class="col-sm-2 col-form-label font-weight-bold text-right">Transport Dest:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.transportDest }}</label>

              <!-- <input type="text" class="col-sm-3 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.transportDest"> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Bank:</label>
              <label class="col-sm-3 col-form-label text-left">{{  order.partner.bank }}</label>

              <!-- <textarea class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.bank" placeholder="Bank Information"></textarea> -->
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label font-weight-bold text-right">Note:</label>
              <label class="col-sm-8 col-form-label text-left">{{  order.partner.note }}</label>
              <!-- <textarea class="col-sm-8 form-control" :disabled="order.partner.nbr!=''" v-model="order.partner.note" placeholder="Special Instructions"></textarea> -->
            </div>
           
          </CCardBody>
        </CCard>

      </div>


        <!--------------------------------- 2. Manage items in Order -------------------------------------------->
  
        <div v-else-if="wiz == 2">
          <div class="row">
            <div class="col">
              <CButton color="dark" class="ml-3 float-left"  v-on:click.prevent="wizChange(1)">Back</CButton>
            </div>
            <div class="col">
              <p class="text-center font-weight-bold">Order Items</p>
            </div>
            <div class="col">
                <CButton color="dark" class="float-right"  :disabled="rowItems.length==0" v-on:click.prevent="wizChange(3)">Next</CButton>
            </div>
          </div>
          <hr />
          <br>
	        <vue-good-table ref="orderTable" :columns="colItems" :rows="rowItems"
              styleClass="vgt-table bordered condensed"
              :lineNumbers="true"
              :sort-options="{enabled: false}"
              @on-row-click="item2RowClicked"
              :selectOptions="{
									enabled: true,
									selectOnCheckboxOnly: false, 
							}"
          >
            <div slot="table-actions">
              <CButton color="dark" class="mr-3 text-right" v-on:click.prevent="addMoreItems()">Add Item</CButton>
            </div>

            <div slot="selected-row-actions">
              <CButton :disabled="this.rowItemSelected == null" color="dark" class="mr-3 text-right" 
                        v-on:click.prevent="modifyItemClicked()">Modify</CButton>
              <CButton :disabled="this.rowItemSelected == null" color="dark" class="mr-3 text-right" 
                      v-on:click.prevent="removeItemClicked()">Remove</CButton>
            </div>

          </vue-good-table>

          <br>
          <div class="form-group row">
				    <label class="col-sm-12 font-weight-bold text-right">Order Total: {{ order.total.toFixed(2) }}</label>
			    </div>

        </div>


        <!--------------------------------- 3. Payment and Delivery -------------------------------------------->

        <div v-else-if="wiz == 3">
          <div class="row">
            <div class="col">
              <CButton color="dark" class="ml-3 float-left"  v-on:click.prevent="wizChange(2)">Back</CButton>
            </div>
            <!-- <div class="col">
              <p class="text-center font-weight-bold">Payment</p>
            </div> -->
            <a href='#' class="float-right mr-3" style="color: black" 
                    v-on:click.prevent="serverCall('getQuote')"><u>Quote</u></a>
            <a href='#' class="float-right mr-3" style="color: black"  
                    :disabled="order.nbr==null"
                    v-on:click.prevent="serverCall('getOrderReceipt')"><u>{{orderType}} Receipt</u></a>
            <a href='#' class="float-right mr-3" style="color: black"  
                    v-if="cashSale==true" :disabled="order.nbr==null || order.tax==0"
                    v-on:click.prevent="serverCall('getCashMemo')"><u>Tax Receipt</u></a>
            <a href='#' class="float-right mr-3" style="color: black"  
                    v-if="cashSale==false" :disabled="order.nbr==null || order.tax==0"
                    v-on:click.prevent="serverCall('getInvoice')"><u>Tax Invoice</u></a>
          </div>

          <hr />

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Sale Date</label>
            <div class="col-sm-2 text-left">
              <datepicker v-model="order.invoiceDate" name="currentDate" :disabled-dates="state.disabledDates"></datepicker>
            </div>
            <div class="col-sm-2"></div>
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Alt Order Nbr</label>
            <input type="text" class="col-sm-3 form-control text-left" v-model="order.altNbr">
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Sale Invoice?</label>
            <div class="form-check form-check-inline">
              <input v-model="order.invoiceIssued" class="form-check-input" type="radio" id="inlineRadio3" value="Yes" />
              <label class="form-check-label" for="inlineRadio3">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="order.invoiceIssued" class="form-check-input" type="radio" id="inlineRadio4" value="No" />
              <label class="form-check-label" for="inlineRadio4">No</label>
            </div>

            <div class="col-sm-3"></div>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">In State Sale?</label>
            <div class="form-check form-check-inline">
              <input v-model="order.partner.inState" class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="order.partner.inState" class="form-check-input" type="radio" id="inlineRadio2" value="No" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">GSTN</label>
            <input type="text" :disabled="order.invoiceIssued=='No'" class="col-sm-2 form-control" v-model="order.partner.GSTN">
            <div class="col-sm-2"></div>
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Transporter</label>
            <select class="col-sm-3 form-control form-select" v-model="order.partner.transporter">
              <option v-for="transporter in transporterList" v-bind:key="transporter.nbr" v-bind:value="transporter.name">
                {{ transporter.name }}
              </option>
            </select>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Delivered?</label>
            <div class="form-check form-check-inline">
              <input v-model="order.delivered" class="form-check-input" type="radio" id="inlineRadio3" value="Yes" />
              <label class="form-check-label" for="inlineRadio3">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="order.delivered" class="form-check-input" type="radio" id="inlineRadio4" value="No" />
              <label class="form-check-label" for="inlineRadio4">No</label>
            </div>

            <div class="col-sm-3"></div>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Delivery Date</label>
            <div class="col-sm-3 text-left">
              <datepicker v-model="order.deliveryDate" :disabled-dates="state.disabledDates" :clear-button="true"></datepicker>
            </div>
          </div>

          <hr>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Order Total</label>
            <label class="col-sm-2 col-form-label text-left">{{order.total.toFixed(2)}}</label>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Paid So Far</label>
            <label class="col-sm-2 col-form-label text-left">{{order.paidSoFar}}</label>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Outstanding</label>
            <label class="col-sm-2 col-form-label text-left">{{order.outstanding}}</label>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Payment Date</label>
            <label class="col-sm-2 col-form-label text-left">{{order.curPayDate}}</label>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Bank Paid</label>
            <label class="col-sm-2 col-form-label text-left">{{ order.paidBank }}</label>

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Cash Paid</label>
            <label class="col-sm-2 col-form-label text-left">{{ order.paidCash }}</label>

            <!-- <label class="col-sm-2 col-form-label font-weight-bold text-right">Cash Paid</label>
            <input type="number" class="col-sm-2 form-control" v-model="order.paidCash"
                @focus="$event.target.select()" v-on:keyup="onAmtChange()">

            <label class="col-sm-2 col-form-label font-weight-bold text-right">Bank Paid</label>
            <input type="number" class="col-sm-2 form-control" v-model="order.paidBank"
                  @focus="$event.target.select()"  v-on:keyup="onAmtChange()"> -->

          </div>
          <div class="form-group row justify-content-center">
              <CButton color="dark" class="mr-3"
                    v-on:click.prevent="openPaymentModal()">Payments</CButton>
          </div>

          <!--
          <div class="form-group row justify-content-center">
              <CButton color="dark" class="mr-3" 
                    v-on:click.prevent="paymentModal=true">Add Payment</CButton>
          </div>

          <div v-for="payment in order.payments" :key="payment.date">
            <div class="row">
              <div class="col-sm-3"></div> 
              <div class="col-sm-2 text-left">{{ new Date(payment.date).toLocaleDateString() }}</div>
              <div class="col-sm-2 text-left">{{ payment.type }}</div>
              <div class="col-sm-2 text-left">{{ payment.amt }}</div>
              <div class="col-sm-3"></div> 
            </div>
          </div>
          -->

          <hr>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label font-weight-bold text-right">Note</label>
            <textarea class="col-sm-8 form-control" v-model="order.note" placeholder="Order Note"></textarea>
          </div>

          <div class="form-group row justify-content-center">
              <CButton color="dark" class="mr-3" :disabled="disabled"
                    v-on:click.prevent="serverCall('saveOrder')">Save</CButton>
              <CButton color="dark" class="float-right"  v-on:click.prevent="clearOrder()">Done</CButton>
          </div>
          <!-- <div class="form-group row justify-content-center">
              <CButton color="dark" class="float-right mr-3"  v-on:click.prevent="serverCall('getQuote')">Quote</CButton>
              <CButton color="dark" class="float-right mr-3" v-on:click.prevent="serverCall('getOrderReceipt')"
                    :disabled="order.nbr==null">{{orderType}} Receipt</CButton>
              <CButton color="dark" class="float-right mr-3" v-on:click.prevent="serverCall('getCashMemo')"
                      v-if="cashSale==true" :disabled="order.nbr==null || order.tax==0">Tax Receipt</CButton>
              <CButton color="dark" class="float-right mr-3"  v-on:click.prevent="serverCall('getInvoice')"
                      v-if="cashSale==false" :disabled="order.nbr==null || order.tax==0">Tax Invoice</CButton>
          </div> -->

          <div class="row justify-content-md-center">
            <label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
            <scale-loader :loading="loading"></scale-loader>
          </div>

        </div>

        <!-- *********   4. Receipt   *********-->
        <div v-else-if="wiz == 4">
            <div class="row">
              <div class="col">
                <CButton color="dark" class="ml-3 float-left"  v-on:click.prevent="wizChange(3)">Back</CButton>
              </div>
              <div class="col">
                  <p class="text-center font-weight-bold">Print {{receiptType}}</p>
              </div>
              <div class="col">
                  <CButton color="dark" class="float-right"  v-on:click.prevent="printReceipt()">Print</CButton>
              </div>
            </div>
            <hr />
            <br>

            <div id="receipt">
              <span v-html="receiptHTML"></span>
            </div>
            <br>
        </div>

    </div>

</template>

<script>

import { firebaseAuth } from '../../firebaseConfig.js';

import Datepicker from 'vuejs-datepicker'

import axios from 'axios'
import ScaleLoader from '@/views/utils/ScaleLoader'
var numeral = require('numeral')
import { ModelListSelect } from 'vue-search-select'
import Helper from "../utils/Helper.vue"

export default {
  name: 'Home',

	mixins: [
		Helper
	],

  components: {
		    Datepicker,
        ScaleLoader,
        ModelListSelect,
	  },

  data () {
    return {
      orgId: null,
      disabled: false,
      timeout: null,
      pageTitle: 'Order',
      receiptHTML: null,
      receiptType: '',

      loading: false,
			loadingMsg: null,

      msg: '',
      existingOrderNbrShort: null,

      currentDate: null,
      state: {
        // Allow entry for
        // Current Date - 30 days ---> Curent Date
        disabledDates: {
          to: new Date(new Date().getTime() - (180 * 24 * 60 * 60 * 1000)),
          from: new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)),
        }
      },

      orderItemsModal: false,

      paymentModal: false,
      wiz: 1,
      orderType: '',
      partnerType: '',
      location: '',
      cashSale: false,

      item2ListAll: [],

      item1List: [],
      item1sel: {},
      item1dd: {}, // item1 selected in dropdown

      item2List: [],
      item2Selected: false,
      item2sel: { item1: '', item2: '', price: 0, qty: 0, tax: 0, surcharge: 0, discount: 0, total: 0 },

      item2dd: {}, // item2 selected in dropdown

      colItems: [
        {
          label: 'Item1',
          field: 'item1Name',
          thClass: 'text-left'
        },
        {
          label: 'Item2',
          field: 'name',
          thClass: 'text-left'
        },
        {
          label: 'Unit Price',
          field: 'price',
          thClass: 'text-right',
          type: 'number',
          formatFn: this.formatNumberFn
        },
        {
          label: 'Unit Discount',
          field: 'discount',
          thClass: 'text-right',
          type: 'number',
          formatFn: this.formatNumberFn
        },
        {
          label: 'Quantity',
          field: 'qty',
          thClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Tax',
          field: 'tax',
          thClass: 'text-right',
          tdClass: 'text-right',
          formatFn: this.formatNumberFn
        },
        {
          label: 'Line Total',
          field: 'total',
          thClass: 'text-right',
          type: 'number',
          formatFn: this.formatNumberFn
        },
      ],

      rowItems: [],
      rowItemSelected: null,

      // Statuses: 10 - Started, 20 - Saved, 30 - Paid, 40 - Delivered
      order: {},

      paidAmt: 0,
      returnAmt: 0,
      curPayDate: null,
      curPayType: 'Bank',
      curPayAmt: 0,
      updatePayment: false,
      rowPaymentSelected: null,
      curPayment:{'date':new Date(), 'paidBank': 0, 'paidCash': 0},
      taxes: [],
      taxesCnt: 0,

      // DropDownList
      selectedPartner: {},
      inputValue: '',
      partnerList: [],
      transporterList: [],
      stateList: ["Andhra Pradesh","Andaman and Nicobar Islands","Arunachal Pradesh","Assam","Bihar",
                  "Chandigarh","Chhattisgarh","Dadar and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep",
                  "Puducherry","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand",
                  "Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram",
                  "Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura",
                  "Uttar Pradesh","Uttarakhand","West Bengal"],
    
      editPartner: false,

      originalSearchCriteria: null,

    }
  },

  computed: {
  },


  // watch: {
  //   messageList: function(newList) {
  //     const nextMessage = newList[newList.length - 1]
  //     const isIncoming = (nextMessage || {}).author !== 'you'
  //     if (isIncoming && this.toggledOpen) {
  //       this.handleMessageResponseSound()
  //     } 
  //   }
  // },

  created () {
  },

  activated () {
  },

  beforeMount: function () {
    // if (localStorage.orderDate){
    //   this.currentDate = new Date(localStorage.orderDate)
    // }
    // else {
    //   this.currentDate = new Date()
    // }

    this.currentDate = new Date()
    this.initializeOrder()
  },

  mounted () {
    this.orgId = this.$route.params['orgId']

    this.originalSearchCriteria = this.$route.params.originalSearchCriteria
    this.orderType = this.$route.params.orderType
    if (this.orderType == 'Sale') {
      this.partnerType = 'Customer'
    } else {
      this.partnerType = 'Supplier'
    }
    this.location = this.$route.params.location
    this.pageTitle = this.location + ' ' + this.orderType + ' ' + this.pageTitle

    this.$el.addEventListener('click', this.onClick);

    // Get Items for location
    this.serverCall('getItemsForLocation')

    // Get Tax info
    this.taxes = this.getTaxes()
    this.taxesCnt = this.taxes.length

    //this.initializeItem2sel(true)

    // Modify Order called for existing Order
    if (this.$route.params.order) {
      this.order = this.$route.params.order
      this.currentDate = this.order.createDate
      this.rowItems = this.order.items
      // this.selectedPartner = this.order.partner
      this.inputValue = this.order.partner.name
      this.wizChange(1)
    } else {
      this.wizChange(0)
    }
  },

  beforeDestroy () {
     // clear the timeout before the component is destroyed
     clearTimeout(this.timeout)
  },

  methods: {
    // orderDateChanged(date) {
    //   localStorage.orderDate = date
    //   this.currentDate = date
    //   this.order.curPayDate = date
    // },

    formatNumberFn: function (value) {
      return value.toFixed(2)
    },

    // createNewPartner(){
    //   this.initializeOrderPartner()
    //   this.editPartner = true
    // },

		// Open Order List
		openOrderList () {
      var self = this
      self.alert = null

      self.$router.push({
        name: 'Orders',
        params: {
          location: self.location,
          locationType: 'standard',
          orderType: self.orderType,
          opType: 'Entry',
          originalSearchCriteria: self.originalSearchCriteria
        }
			})
		},

    // paid: Yes, No, Partial
    // delivered: Yes, No, Partial
    initializeOrder () {
      this.order = {
        nbr: null,
        notificationToken: '',
        invoiceIssued: 'Yes',
        GSTN: '',
        altNbr: null,
        inState: 'Yes',
        type: this.orderType,
        location: this.location,
        items: [],
        status: 0,
        serviceTicket: '',
        note: '',
        tax: 0,
        total: 0,
        total1: 0,
        total2: 0,
        createDate: this.getMidDay(this.currentDate),
        invoiceDate: this.getMidDay(this.currentDate),
        paid: 'No',
        curPayDate: null,
        curPayType: 'Bank',
        curPayAmt: 0,
        payments: [],
        paidSoFar: 0,
        paidCash: 0,
        paidBank: 0,
        outstanding: 0,
        delivered: 'No',
        deliveryDate: null,
        deliveryReady: 'No',
        cancelled: 'No',
        cancelledDate: null,
        updateDate: null,
        partner: {}
      }

      if (this.location=="Shop"){
        this.order.delivered="Yes"
        this.order.deliveryDate = this.getMidDay(this.currentDate)
      }

      // Initialize partner
      this.initializeOrderPartner()

      // Initialize order taxes
      for (var i = 0; i < this.taxesCnt; i++) {
        this.order[this.taxes[i].name] = 0
      }
    },

    initializeOrderPartner(){        
      this.order.partner = 
        {
          nbr: '',
          type: this.orderType,
          name: '',
          phone: '',
          email: '',
          GSTN: '',
          PAN: '',
          aadhar: '',
          state: '',
          address1: '',
          address2: '',
          address3: '',
          transporter: '',
          transportDest: '',
          bank: '',
          note: '',
          vgtSelected: false,
          optOutOfNetwork: 'No'
        }
        //this.$refs.partnerName.focus()
    },
    // *********************************  Wiz = 2 : Order Items  *****************************************************

    initializeItem2sel (applyTax) {
      this.item2dd = {}
      this.item2sel.name = ''
      this.item2sel.item1name = ''
      this.item2sel.price = 0.00
      this.item2sel.tax = 0.00

      // If Seller State = Buyer State, enable CGST and SGST, Else enable IGST
      // order.partner.state, this.$store.state.orgState
      // this.taxes

      // Initialize taxes
      /*
      var taxFlag = 'No'
      for (var i = 0; i < this.taxesCnt; i++) {
        // Item flag - applyTax overrides organization default flag.
        if (applyTax == false) { 
          taxFlag = 'No'
        } else { 
          taxFlag = this.taxes[i].default 

          if (this.taxes[i].name == 'CGST' || this.taxes[i].name == 'SGST'){
            // If Buyer State = Seller State
            if (this.order.partner.state == this.getOrgState()){
              taxFlag = 'Yes'
            } else {
              taxFlag = 'No'
            }
          }

          if (this.taxes[i].name == 'IGST'){
            // If Buyer State = Seller State
            if (this.order.partner.state == this.getOrgState()){
              taxFlag = 'No'
            } else {
              taxFlag = 'Yes'
            }
          }

        }

        this.item2sel[this.taxes[i].name] = { amt: 0.00, apply: taxFlag }
      }
      */

      this.item2sel.surcharge = 0.00
      this.item2sel.discount = 0.00

      this.item2sel.qty = 1
      this.item2sel.baseTotal = 0.00
      this.item2sel.total = 0.00
    },

    onItem1Change () {
      this.item1sel = JSON.parse(JSON.stringify(this.item1dd))
      this.item2List = this.getItem2List(this.item1sel)
      //this.initializeItem2sel(this.item1sel.applyTax)
      this.initializeItem2sel(true)
    },

    onItem2Change () {
      if (!this.item2dd){
        return
      }
      this.item2Selected = true
      this.item2sel = JSON.parse(JSON.stringify(this.item2dd))
      this.item2sel.price = Number(numeral(this.item2dd.price).format('0.00'))
      this.item2sel.taxrate = Number(numeral(this.item2dd.taxrate).format('0.00'))
      // Item2 HSN same as item1 HSN
      this.item2sel.HSN = this.item1sel.HSN
      this.item2sel.qty = 1.00
      this.item2sel.tax = 0.00
      this.item2sel.total = 0.00
      this.item2sel.surcharge = 0.00
      this.item2sel.discount = 0.00

      this.recalOrderRow()

      /*   
      this.item2sel.baseTotal = Number(this.item2sel.price.toFixed(2))

      // Initialize taxes
      var taxFlag = 'No'
      var taxAmt = 0
      for (var i = 0; i < this.taxesCnt; i++) {
        // Item1 flag - applyTax overrides organization default flag.
        if (this.item1sel.applyTax == false) {
          taxFlag = 'No'
          taxAmt = 0
        } else {
          taxFlag = this.taxes[i].default

          if (this.taxes[i].name == 'CGST' || this.taxes[i].name == 'SGST'){
            // If Buyer State = Seller State
            if (this.order.partner.state == this.getOrgState()){
              taxFlag = 'Yes'
            } else {
              taxFlag = 'No'
            }
          }

          if (this.taxes[i].name == 'IGST'){
            // If Buyer State = Seller State
            if (this.order.partner.state == this.getOrgState()){
              taxFlag = 'No'
            } else {
              taxFlag = 'Yes'
            }
          }

          if (taxFlag == 'Yes') {
            taxAmt = (this.item2sel.price) * (this.taxes[i].rate / 100)
            taxAmt = Number(taxAmt.toFixed(2))
          } else {
            taxAmt = 0
          }
        }
        this.item2sel[this.taxes[i].name] = { amt: taxAmt, apply: taxFlag }
        this.item2sel.tax = this.item2sel.tax + taxAmt
      }

      this.item2sel.surcharge = 0
      this.item2sel.discount = 0
      this.item2sel.qty = 1
      this.item2sel.total = this.item2sel.baseTotal + this.item2sel.tax
      // this.item2sel.total = Number(this.item2sel.total.toFixed(2))
      this.item2sel.total = Math.round(this.item2sel.total)
      */

    },

    //  Calculate effective sale price after any change in item - price, discount, qty
    recalOrderRow () {
      if (!this.item2sel.price) { 
        this.item2sel.price = 0
      }
      else {
        this.item2sel.price = parseFloat(this.item2sel.price)
      }

      if (!this.item2sel.qty) { 
        this.item2sel.qty = 0
      }
      else {
        this.item2sel.qty = parseFloat(this.item2sel.qty)
      }

      if (!this.item2sel.discount) { 
        this.item2sel.discount = 0 
      }
      else {
        this.item2sel.discount = parseFloat(this.item2sel.discount)
      }

      if (!this.item2sel.tax) { 
        this.item2sel.tax = 0 
      }
      else {
        this.item2sel.tax = parseFloat(this.item2sel.tax)
      }

      var priceBaseForTax = (this.item2sel.price - this.item2sel.discount) * this.item2sel.qty 
      if (this.item2sel.taxrate != 0){
        this.item2sel.tax = priceBaseForTax * this.item2sel.taxrate / 100
        this.item2sel.tax = Number(this.item2sel.tax.toFixed(2))
      }
      this.item2sel.total = priceBaseForTax + this.item2sel.tax
      this.item2sel.total = Number(this.item2sel.total.toFixed(2))

      // Reactiviy in depth - https://vuejs.org/v2/guide/reactivity.html
      this.item2sel = Object.assign({}, this.item2sel, {})
    },

    item2RowClicked(params){
			// Current selected Row
			if (this.rowItemSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.rowItemSelected.originalIndex!=params.row.originalIndex)
					this.rowItemSelected.vgtSelected = false
			}
			// New selected row
			this.rowItemSelected = params.row
    },

    addMoreItems () {
      // Uncheck any selected row
      if (this.rowItemSelected != null) {
        this.rowItemSelected.vgtSelected = false
        this.rowItemSelected = null
      }
      this.initializeItem2sel(true)
      if (this.item1List.length > 0) {
        this.item1sel = this.item1List[0]
        this.item1dd = this.item1sel
        this.item2List = this.getItem2List(this.item1sel)
      }
      this.item2Selected = false
      //this.wizChange(2)
      this.orderItemsModal = true
    },
    
    // User clicks on 'Modify' (item2)
    modifyItemClicked () {
      if (this.rowItemSelected == null) {
        this.$alert("Item not selected.", '', 'error', { confirmButtonColor: '#161c2d' })
      } else {
        this.item2sel = this.rowItemSelected
        this.item2Selected = true
        // Set item1dd and item2dd
        let i = 0
        for (i=0; i<this.item1List.length; i++){
          if (this.item1List[i].name == this.rowItemSelected.item1Name)
            break
        }
        this.item1dd = this.item1List[i]
        this.item1sel = this.item1List[i]

        this.item2List = this.getItem2List(this.item1dd)
        this.item2dd = this.item2List.find(o => o.name === this.rowItemSelected.name)

        // for (i=0; i<this.item2List.length; i++){
        //   if (this.item2List[i].name == this.rowItemSelected.name)
        //     break
        // }
        // this.item2dd = this.item2List[i]

        this.orderItemsModal = true
        
        /*
        // Remove current entry
        this.rowItems.splice(this.rowItemSelected.originalIndex, 1)
        this.recalOrder()
        this.rowItemSelected.vgtSelected = false
        this.wizChange(2)
        */
      }
    },

    // User clicks on 'Remove'
    removeItemClicked () {
      if (this.rowItemSelected == null) {
        this.$alert("Item not selected.", '', 'error', { confirmButtonColor: '#161c2d' })
      } else {
        this.rowItems.splice(this.rowItemSelected.originalIndex, 1)
        this.recalOrder()
      }
    },

    // Save current item and add to order
    saveItem () {
      this.item2sel.item1Name = this.item1sel.name
      let currentRows = JSON.parse(JSON.stringify(this.rowItems))
      let newRow = JSON.parse(JSON.stringify(this.item2sel))

      // Remove Existing item
      if (this.rowItemSelected != null){
        currentRows.splice(this.rowItemSelected.originalIndex, 1)
      }

      currentRows.push(newRow)
      this.rowItems = currentRows

      if (this.rowItemSelected != null) {
        this.rowItemSelected.vgtSelected = false
        this.rowItemSelected = null
      }

      this.order.status = 20
      // Calculate Order Total
      this.recalOrder()
      this.order.items = this.rowItems
      this.orderItemsModal = false
      //this.wizChange(3)
    },

    recalOrder () {
      this.order.total = 0
      this.order.baseTotal = 0
      this.order.total1 = 0
      this.order.total2 = 0
      this.order.tax = 0
      for (var i = 0; i < this.taxesCnt; i++) {
        this.order[this.taxes[i].name] = 0
      }

      var row
      var i = 0
      var len = this.rowItems.length
      for (i = 0; i < len; i++) {
        row = this.rowItems[i]
        this.order.total = this.order.total + row.total
        this.order.tax = this.order.tax + row.tax

        /*
        if (row.tax != 0) {
          this.order.total1 = this.order.total1 + row.total
          this.order.baseTotal = this.order.baseTotal + row.baseTotal
          this.order.baseTotal = Number(this.order.baseTotal.toFixed(2))
        } else {
          this.order.total2 = this.order.total2 + row.total
        }
        this.order.tax = this.order.tax + row.tax
        // For each item, get taxes and update order accordingly.
        for (var j = 0; j < this.taxesCnt; j++) {
          this.order[this.taxes[j].name] = this.order[this.taxes[j].name] + row[this.taxes[j].name].amt
        }
        */

      }

      this.order.outstanding = this.order.total - this.order.paidSoFar
      // For new Order, assume amount is paid. outstanding = paid amount
      // if (!this.order.nbr){
      //   this.curPayAmt = this.order.total
      // }
    },


    // *********************************  Payment Modifications  *****************************************************

    openPaymentModal(){
      // If no payment exists, open a new row
      if (this.order.payments.length == 0){
        this.addMorePayments ()
      }
      this.paymentModal=true
    },

    paymentRowClicked(params){
			// Current selected Row
			if (this.rowPaymentSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.rowPaymentSelected.originalIndex!=params.row.originalIndex)
					this.rowPaymentSelected.vgtSelected = false
			}
			// New selected row
			this.rowPaymentSelected = params.row
    },

    addMorePayments () {
      // Uncheck any selected row
      if (this.rowPaymentSelected != null) {
        this.rowPaymentSelected.vgtSelected = false
        this.rowPaymentSelected = null
      }

      this.curPayment = {'date':new Date().toISOString().split('T')[0], 'paidBank': 0, 'paidCash': 0},
      this.updatePayment = true
    },

    // User clicks on 'Modify'
    modifyPaymentClicked () {
      if (this.rowPaymentSelected == null) {
        this.$alert("Payment not selected.", '', 'error', { confirmButtonColor: '#161c2d' })
      } else {
        this.curPayment = this.rowPaymentSelected
        this.updatePayment = true
        this.rowPaymentSelected.vgtSelected = true
      }
    },

    // User clicks on 'Remove'
    removePaymentClicked () {
      if (this.rowPaymentSelected == null) {
        this.$alert("Payment not selected.", '', 'error', { confirmButtonColor: '#161c2d' })
      } else {
        this.order.payments.splice(this.rowPaymentSelected.originalIndex, 1)
        this.updatePayments()
      }
    },

    // Save current payment
    savePayment () {
      // if Modifying existing row, Remove Existing item
      if (this.rowPaymentSelected != null){
        this.order.payments.splice(this.rowPaymentSelected.originalIndex, 1)
        this.rowPaymentSelected.vgtSelected = false
        this.rowPaymentSelected = null
      }
      this.curPayment['date'] = this.curPayment['date'].toISOString().split('T')[0]
      this.curPayment['paidBank'] = parseFloat(this.curPayment['paidBank'])
      this.curPayment['paidCash'] = parseFloat(this.curPayment['paidCash'])
      this.order.payments.push(this.curPayment)
      this.updatePayments()
      this.updatePayment = false
    },

    updatePayments() {
      // if (!this.order.paidCash)
      //   this.order.paidCash = 0
      // if (!this.order.paidBank)
      //   this.order.paidBank = 0
      
      // this.order.paidCash = parseFloat(this.order.paidCash)
      // this.order.paidBank = parseFloat(this.order.paidBank)

      let paidBank = 0
      let paidCash = 0
      let i = 0
      while (i < this.order.payments.length){
        paidBank = paidBank + this.order.payments[i].paidBank
        paidCash = paidCash + this.order.payments[i].paidCash
        this.order.curPayDate = this.order.payments[i].date
        i++
      }
      this.order.paidBank = parseFloat(paidBank)
      this.order.paidCash = parseFloat(paidCash)

      //this.order.paidSoFar = this.order.paidCash + this.order.paidBank
      this.order.paidSoFar = this.order.paidCash + this.order.paidBank
      var delta = this.order.total - this.order.paidSoFar
      if (delta > 0){
        this.order.outstanding = delta.toFixed(2)
      }
      else{
        this.order.outstanding = 0
      }
    },

    closePaymentModal() {
      this.updatePayment = false
      this.paymentModal = false
    },

    // *********************************  Wiz = 3 : Payment  *****************************************************

    clearOrder () {
      this.$confirm("Return to Order List? Unsaved changes will be discarded.").then(() => {
          this.item1sel = {}
          this.item2sel = {}
          this.item2dd = {}
          this.rowItems = []
          this.order = {}

          // this.initializeItem2sel(true)
          // if (this.item1List.length > 0) {
          //   this.item1sel = this.item1List[0]
          //   this.item2List = this.getItem2List(this.item1sel)
          // }

          //this.wizChange(0)
          this.openOrderList()
      })
    },

    // Replaces 'addPayment' - Simplified logic
    onAmtChange() {
      if (!this.order.paidCash)
        this.order.paidCash = 0
      if (!this.order.paidBank)
        this.order.paidBank = 0
      
      this.order.paidCash = parseFloat(this.order.paidCash)
      this.order.paidBank = parseFloat(this.order.paidBank)

      //this.order.paidSoFar = this.order.paidCash + this.order.paidBank
      this.order.paidSoFar = this.order.paidCash + this.order.paidBank
      var delta = this.order.total - this.order.paidSoFar
      if (delta > 0){
        this.order.outstanding = delta.toFixed(2)
      }
      else{
        this.order.outstanding = 0
      }
    },

    // Add Current Payment record
    addPayment () {
      if (this.curPayAmt != 0){
        if (this.curPayDate==null){
          this.$alert('Please specify payment date')
          return
        }
        this.order.paidSoFar = this.order.paidSoFar + this.curPayAmt - this.returnAmt
        var curPayment = {'date': this.curPayDate, 'type': this.curPayType, 'amt': this.curPayAmt - this.returnAmt}
        this.order.payments.push(curPayment)

        this.order.curPayType = this.curPayType
        this.order.curPayDate = this.curPayDate

        if (this.curPayType=='Cash')
          this.order.paidCash = this.order.paidCash + this.curPayAmt - this.returnAmt
        else 
          this.order.paidBank = this.order.paidBank + this.curPayAmt - this.returnAmt
      }

      this.curPayAmt = 0
      this.returnAmt = 0
      this.order.outstanding = this.order.total - this.order.paidSoFar

      // Close Payment Modal
      this.paymentModal = false
    },

    // saveOrder () {
    //   // Add Current Payment record
    //   if (this.curPayAmt != 0){
    //     if (this.order.curPayDate==null){
    //       this.$alert('Please specify payment date')
    //       return
    //     }
    //     this.order.paidSoFar = this.order.paidSoFar + this.curPayAmt - this.returnAmt
    //     var curPayment = {'date': this.order.curPayDate, 'type': this.curPayType, 'amt': this.curPayAmt - this.returnAmt}
    //     this.order.payments.push(curPayment)

    //     this.order.curPayType = this.curPayType

    //     if (this.curPayType=='Cash')
    //       this.order.paidCash = this.order.paidCash + this.curPayAmt - this.returnAmt
    //     else 
    //       this.order.paidBank = this.order.paidBank + this.curPayAmt - this.returnAmt
    //   }

    //   this.curPayAmt = 0
    //   this.returnAmt = 0
    //   this.order.outstanding = this.order.total - this.order.paidSoFar
    //   this.serverCall('saveOrder')
    // },

    // *********************************  Wiz = 4 : Payment & Delivery  *****************************************************

    onPaidAmtChange () {
      if (!this.curPayAmt) { this.curPayAmt = 0 }
      this.curPayAmt = parseFloat(this.curPayAmt)
      var retAmt = this.curPayAmt - this.order.outstanding
      if (retAmt < 0) { 
        retAmt = 0 
      }
      this.returnAmt = Number(retAmt.toFixed(2))
      if ((this.curPayAmt - this.order.outstanding) >= 0) {
        this.order.paid = 'Yes'
        //this.order.curPayDate = this.getMidDay(this.curPayDate)
      } else {
        this.order.paid = 'No'
        //this.order.curPayDate = null
      }
    },

    onDeliveryChange () {
      // this.order.deliveryDate = this.currentDate
    },

    onCancelChange () {
      this.order.status = 50
      // this.order.cancelledDate = this.currentDate
    },

    // *********************************  Main  *****************************************************

    wizChange (pageNbr) {
      switch (pageNbr) {
        // 0: Select Date
        case 0:
          this.initializeOrder()
          this.wiz = 1
          break

        // 1: Enter customer details
        case 1:
          // Set createDate to date specified by user.
          this.wiz = 1
          break

        // 2: Show Order - Item List
        case 2:
          this.wiz = 2
          break

        // 3: Payment and Delivery
        case 3:
          if (this.inputValue != '') {
            this.order.partner.name = this.inputValue
          }
          this.wiz = 3
          break

        // Receipt or Invoice printing  
        case 4:
          this.wiz = 4
          break

        // Messaging  
        case 5:
          this.waURL = "https://wa.me/" + this.order.partner.phone
          this.wiz = 5
          break

        default:
          this.wiz = pageNbr
      }
    },

    getItem2List (item1, item2ListAllCopy) {
      if (!item1 || item1.name == '') { return }

      var i = 0
      var len = this.item1List.length
      // Get Item1 Index
      for (i = 0; i < len; i++) {
        if (this.item1List[i].name == item1.name) {
          break
        }
      }

      if (i >= len) {
        self.$alert('Invalid Item1', '', 'error', { confirmButtonColor: '#161c2d' })
        return []
      } else {
        // Get Item2 List for the given Item1
        return (this.item2ListAll[i])
      }
    },

    serverCall (name) {
      var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
            switch (name) {
              case 'getItemsForLocation':
                self.getItemsForLocation(idToken)
                break

              case 'getOrder':
                self.getOrder(idToken)
                break

              case 'saveOrder':
                self.saveOrderToDB(idToken)
                break

              case 'getInvoice':
                self.getReceipt(idToken, 'Invoice')
                break

              case 'getQuote':
                self.getReceipt(idToken, 'Quote')
                break

              case 'getOrderReceipt':
                self.getReceipt(idToken, 'OrderReceipt')
                break

              case 'getCashMemo':
                self.getReceipt(idToken, 'CashMemo')
                break

              default:
                console.log('Unknown function: ' + name)
            }
          })
        } else {
          self.updateResultMsg = 'User logged out. Please login.'
          self.wiz = 4
        }
      })
    },

    // Fetch Item1s for the selected Location
    async getItemsForLocation (authToken) {
      var self = this

      // Set loading message
      self.loading = true
      self.loadingMsg = 'Fetching Data...'

      var formData = new FormData()
      formData.append('location', this.location)
      formData.append('orderType', this.orderType)

      await axios({
        method: 'POST',
        baseURL: process.env.VUE_APP_ROOT_API,
        url: '/GetItemsForLocation/',
        data: formData,
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'OrgId': self.orgId,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(async function (response) {
          if (response.data.err != '') {
            self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
            console.log('getItemsForLocation - error: ' + response.data.err)
          } else {
            self.item2ListAll = response.data.item2List
            self.item1List = response.data.item1List
            self.partnerList = response.data.partnerList[0]
            self.transporterList = response.data.transporterList[0]
            self.cashSale = response.data.cashSale

            if (self.item1List.length > 0) {
              self.item1dd = self.item1List[0]
              self.item1sel = self.item1List[0]
              self.item2List = self.getItem2List(self.item1sel)
            }
          }
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
        .catch(function (error) {
          self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
    },

    // Get Order for the given order nbr
    getOrder (authToken) {
      var self = this

      if (!this.existingOrderNbrShort) {
        self.$alert('Existing order number not specified.', '', 'error', { confirmButtonColor: '#161c2d' })

        return
			      }

      // Set loading message
      self.loading = true
      self.loadingMsg = 'Fetching Existing Order...'

      var formData = new FormData()
      formData.append('orderNbrShort', this.existingOrderNbrShort)
      formData.append('location', this.location)

      axios({
        method: 'POST',
        baseURL: process.env.VUE_APP_ROOT_API,
        url: '/GetOrder/',
        data: formData,
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'OrgId': self.orgId,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          if (response.data.err != '') {
            self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
            console.log('getOrder - error:' + response.data.err)
            // Reset loading message
            self.loading = false
            self.loadingMsg = null
          } else {
            self.order = response.data.order
            self.rowItems = self.order.items
            self.inputValue = self.order.partner.name
            self.wizChange(3)
            // Reset loading message
            self.loading = false
            self.loadingMsg = null
          }
        })
        .catch(function (error) {
          self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
    },

    // Save Order to database
    async saveOrderToDB (authToken) {
      var self = this

      // Set loading message
      self.loading = true
      self.loadingMsg = 'Saving...'

      // To fix issue of double clicking button
      this.disabled = true
      // Re-enable after 5 seconds
      this.timeout = setTimeout(() => {
        this.disabled = false
      }, 5000)

      self.order.type = self.orderType
      self.order.updateDate = this.getMidDay(new Date())
      var postJson = self.order

      await axios({
        method: 'POST',
        baseURL: process.env.VUE_APP_ROOT_API,
        url: '/SaveOrder/',
        data: postJson,
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'OrgId': self.orgId,
          'Content-Type': 'application/json'
        }
      })
        .then(async function (response) {
          if (response.data.err != '') {
            self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
            console.log('saveOrder - error')
          } else {
            self.order.nbr = response.data.orderNbr
            self.order.orderNbrShort = response.data.orderShortNbr
            self.$alert('Order Saved.', '', 'success', { confirmButtonColor: '#161c2d' })
            //self.$confirm("Order Saved. Return to Order List?").then(() => {
            //    self.openOrderList()
            //})

            //self.order.status = 20
          }
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
        .catch(function (error) {
          self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
    },

    // Get Receipt - Invoice or Cash Memo
    async getReceipt (authToken, receiptType) {
      var self = this
      self.receiptType = receiptType

      // Set loading message
      self.loading = true
      self.loadingMsg = 'Getting Receipt...'

      var formData = new FormData()
      formData.append('order', JSON.stringify(this.order))
      formData.append('receiptType', receiptType)

      await axios({
        method: 'POST',
        baseURL: process.env.VUE_APP_ROOT_API,
        url: '/GetReceipt/',
        data: formData,
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'OrgId': self.orgId,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(async function (response) {
          if (response.data.err != '') {
            self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
            console.log('getReceipt - error')
          } else {
            // var win = window.open("", "Receipt")
            // win.document.body.innerHTML = response.data.receiptHtml
            self.receiptHTML = response.data.receiptHtml
            self.wiz = 4
          }
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
        .catch(function (error) {
          self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
    },

    async printReceipt () {
      await this.$htmlToPaper('receipt')
    },

    // DropDown Methods
    // resetSelection () {
    //   this.editPartner = true
    //   this.selectedPartner = {}
    //   this.$nextTick(() => this.$refs.dropdowninput.focus())
    //   this.$emit('on-item-reset')
    // },
    // selectItem (theItem) {
    //   this.editPartner = false
    //   // Set Order Partner Info
    //   this.order.partner = theItem
    //   this.selectedPartner = theItem
    //   this.inputValue = ''
    //   this.$emit('on-item-selected', theItem)
    // },
    // itemVisible (item) {
    //   const currentName = item.name.toLowerCase()
    //   const currentInput = this.inputValue.toLowerCase()
    //   return currentName.includes(currentInput)
    // }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
/* a {
  color: #42b983;
} */

#myBtn:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 0;
}

</style>
